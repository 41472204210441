<template>
  <div>
    <div
      class="search-wrap"
      style="position: absolute; right: 190px; margin-top: -70px"
    >
      <div class="sort">
        <v-menu
          v-if="config.sortFields != null && config.sortFields.length > 0"
          style="max-width: 300px"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <a v-on="on">{{
              getSortField() != null ? getSortField().title : 'выберите поле'
            }}</a>
          </template>
          <v-card class="mx-auto" max-width="300" tile>
            <v-list dense>
              <v-list-item-group mandatory color="primary">
                <v-list-item
                  v-for="(field, fieldIndex) in config.sortFields"
                  :key="fieldIndex + 'sortitems'"
                >
                  <v-list-item-content
                    @click="
                      sortField = field.path;
                      reload();
                    "
                  >
                    <v-list-item-title
                      >Сортировать по {{ field.title }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>

        <v-btn
          x-small
          outlined
          @click="
            sortAsc = !sortAsc;
            reload();
          "
          v-bind:class="{ sortDesk: sortAsc }"
          v-if="config.sortFields != null && config.sortFields.length > 0"
        >
          <svg
            width="10"
            height="15"
            viewBox="0 0 10 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.16667 -1.42833e-07L4.16667 11.8083L1.175 8.825L-2.7869e-07 10L5 15L10 10L8.825 8.825L5.83333 11.8083L5.83333 -1.99966e-07L4.16667 -1.42833e-07Z"
              fill="#6759D6"
            />
          </svg>
        </v-btn>
        <v-btn @click="filtersOpened = !filtersOpened"
          >Поиск
          <v-icon v-if="!filtersOpened">mdi-magnify</v-icon>
          <v-icon v-if="filtersOpened">mdi-close</v-icon>
        </v-btn>
      </div>
      <v-btn
        outlined
        rounded
        class="download"
        @click="exportRegisterData()"
      ></v-btn>
    </div>
    <Transition name="fade" mode="out-in">
      <form
        class="list-filters"
        v-if="filtersOpened"
        @submit.prevent="reload()"
      >
        <div>
          <template v-for="(filter, filterIndex) in config.filters">
            <v-text-field
              v-if="filter.type === 'STRING'"
              clearable
              filled
              dense
              :label="filter.title"
              v-model="filters[filter.path]"
              :key="filterIndex + 'filterindex'"
            ></v-text-field>
            <v-autocomplete
              v-if="filter.type === 'DICT'"
              clearable
              filled
              dense
              :label="filter.title"
              :key="filterIndex + 'filterindex'"
              v-model="filters[filter.path]"
              :items="filter.dict"
              item-value="code"
              item-text="title"
            ></v-autocomplete>
            <template v-if="filter.type === 'DATE-RANGE'">
              <div :key="filterIndex" class="date-range">
                <label>{{ filter.title }}:</label>
                <date-field
                  label="с"
                  :key="filterIndex + 'filterindexr1'"
                  min-date="2000-01-01"
                  max-date="2029-12-31"
                  @input="filters[filter.path + '_more'] = $event"
                  :value="filters[filter.path + '_more']"
                >
                </date-field>
                <date-field
                  label="по"
                  :key="filterIndex + 'filterindexr2'"
                  min-date="2000-01-01"
                  max-date="2029-12-31"
                  @input="filters[filter.path + '_less'] = $event"
                  :value="filters[filter.path + '_less']"
                >
                </date-field>
              </div>
            </template>
            <template v-if="filter.type === 'NUMBER-RANGE'">
              <div :key="filterIndex" class="number-range">
                <label>{{ filter.title }}:</label>
                <v-text-field
                  label="от"
                  :key="filterIndex + 'filterindexr1'"
                  clearable
                  filled
                  dense
                  v-model="filters[filter.path + '_more']"
                ></v-text-field>
                <v-text-field
                  label="до"
                  :key="filterIndex + 'filterindexr2'"
                  clearable
                  filled
                  dense
                  v-model="filters[filter.path + '_less']"
                ></v-text-field>
              </div>
            </template>
            <template v-if="filter.type === 'REGISTER'">
              <register-select-field
                :key="filterIndex + 'filterindex'"
                :field-title="filter.title"
                :dialog-title="filter.dialogTitle"
                :reg-config="filter.regConfig"
                page-size="10"
                default-tab="0"
                :text-function="
                  (item) => (item != null ? item[filter.itemText] : '')
                "
                :text-search-attribute="filter.itemText"
                :value-object="registerValueObjects[filter.path]"
                @select="
                  filters[filter.path] = $event[filter.itemValue];
                  registerValueObjects[filter.path] = $event;
                  $forceUpdate();
                "
                @clear="
                  delete filters[filter.name];
                  $forceUpdate();
                "
              >
              </register-select-field>
            </template>

            <template v-if="filter.type === 'RADIO'">
              <v-radio-group
                v-model="filters[filter.path]"
                :key="filterIndex + 'filterindex'"
                row
              >
                <v-radio
                  v-for="path in filter.path"
                  :key="path"
                  :value="path"
                  :label="path"
                ></v-radio>
              </v-radio-group>
            </template>
          </template>
        </div>

        <div>
          <v-btn
            outlined
            @click="
              filters = {};
              reload();
            "
          >
            Сбросить все
          </v-btn>
          <v-btn @click="reload()"> Применить </v-btn>
          <input type="submit" hidden />
        </div>
      </form>
    </Transition>
    <table style="width: 100%">
      <thead>
        <tr>
          <th>
            <svg
              width="10"
              height="12"
              viewBox="0 0 10 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.31331 4.43333L2.37331 5.38L4.33331 7.33333L7.79331 3.88L6.85331 2.93333L4.33331 5.45333L3.31331 4.43333ZM8.33331 0H1.66665C0.933313 0 0.333313 0.6 0.333313 1.33333V12L4.99998 10L9.66665 12V1.33333C9.66665 0.6 9.06665 0 8.33331 0ZM8.33331 10L4.99998 8.54667L1.66665 10V1.33333H8.33331V10Z"
                fill="#77809A"
              />
            </svg>
          </th>
          <th>id проводки</th>
          <th>сумма</th>
          <th>дата платежа</th>
          <th>план/факт</th>
          <th>нал/безнал</th>
          <th>отв-ный</th>
          <th>орг–ция</th>
          <!-- <th>доход / расход</th>
          <th>фонд</th> -->
          <th>статья</th>
          <th>контрагент</th>
          <th>год по бюджету</th>
          <th>проект</th>
          <th>договор</th>
          <th>автор записи</th>

          <th>примечание</th>
          <th></th>
        </tr>
      </thead>
      <template v-if="firstPageLoaded">
        <tbody v-for="entry in filteredEntries" v-bind:key="entry.id">
          <WRegisterEntry :entry="entry" />
        </tbody>
      </template>
    </table>
  </div>
</template>

<script>
import api from '@/modules/api';
import DateField from '../elements/DateField.vue';
import RegisterSelectField from './RegisterSelectField.vue';
import WRegisterEntry from './WRegisterEntry.vue';
// import DownloadProgressDialog from '@/components/register/DownloadProgressDialog';
import {
  downloadFile,
  getQueryObject,
  loadDataToObject,
} from '@/modules/CommonUtils';
import { projectsRegisterConfig } from '@/components/register/RegisterConfigs';
import { articlesDict, fundsDict } from './WireDict';
import { wDec } from '@/modules/Wirings';

export default {
  name: 'WRegisterTab',
  //конфиг - джсон с конфигурацией табы реестра, pickMode - запускаем ли в режиме выбора записи, presets - предустановленные фильтры
  props: ['config', 'selectMode', 'presets'],
  components: {
    // DownloadProgressDialog,
    DateField,
    RegisterSelectField,
    // NotificationEntry,
    // Notification44Entry,
    // Notification223Entry,
    // EmployeeEntry,
    // ApplicationEntry,
    // ContractEntry,
    // ProjectEntry,
    // CounterpartyEntry,
    WRegisterEntry,
  },
  data() {
    return {
      loadingInProgress: true,
      filtersOpened: false,
      sortAsc: false,
      sortField: '',
      filters: {},
      registerValueObjects: {}, //хранилище для объектов-значений фильтров типа REGISTER
      page: -1, //при первой загрузке будет сразу увеличено до 0
      hasMorePages: false, // отображать ли кнопку "Загрузить еще"
      entries: [], //загруженные записи реестра
      filteredEntries: [],
      firstPageLoaded: false,
      articlesDict: articlesDict,
      fundsDict: fundsDict,
      projectsDict: { ids: [], projects: [] },
      showDownloadProgressDialog: false,
      year: new Date().getFullYear(),
      projectsRegisterConfig,
    };
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return '';
      }
      let mdate = new Date(date);
      let options = {
        timeZone: 'Europe/Moscow',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      };
      return mdate.toLocaleDateString('ru-RU', options);
    },
  },
  methods: {
    loadData: loadDataToObject,
    //"перезагрузить" реестр: заново с первой страницы (но фильтры и сортировки остаются)
    async reload() {
      const urlParams = this.formQuerySpec();
      urlParams._tab = getQueryObject()._tab;
      await this.$router.push({
        path: window.location.pathname,
        query: urlParams,
      });
      this.filteredEntries = this.entries.filter((item) => item);
      const filterKeys = Object.keys(this.filters);
      console.log('filters', this.filters);
      filterKeys.forEach((filterItem) => {
        this.filteredEntries = this.filteredEntries.filter((item) => {
          if (
            filterItem.split('_').length > 0 &&
            filterItem.split('_')[1] == 'more'
          ) {
            return (
              filterItem
                .split('_')[0]
                .split('.')
                .reduce((o, k) => (o || {})[k], item) >=
              this.filters[filterItem]
            );
          } else if (
            filterItem.split('_').length > 0 &&
            filterItem.split('_')[1] == 'less'
          ) {
            return (
              filterItem
                .split('_')[0]
                .split('.')
                .reduce((o, k) => (o || {})[k], item) <=
              this.filters[filterItem]
            );
          } else {
            return (
              filterItem.split('.').reduce((o, k) => (o || {})[k], item) ==
              this.filters[filterItem]
            );
          }
        });
      });
      if (this.sortField == 'id') {
        if (this.sortAsc == true) {
          this.filteredEntries.sort((a, b) => a.id - b.id);
        } else {
          this.filteredEntries.sort((a, b) => b.id - a.id);
        }
      }
      console.log('filtered entries: ', this.filteredEntries);
    },

    async reloadRows() {
      this.page = -1;
      this.entries = [];
      await this.loadNextPage();
      //   this.entries = this.entries.filter(this.filters);
    },

    //возвращает параметр для запроса get библиотеки api
    formQuerySpec() {
      let query = {};
      query.page = this.page;
      query.size = this.config.pageSize;
      if (this.sortField != null)
        query.sort = this.sortField + (this.sortAsc ? ',asc' : ',desc');

      query.search = '';
      this.removeEmptyFilters();
      for (let filter of this.config.filters) {
        if (
          (filter.type === 'STRING' ||
            filter.type === 'DICT' ||
            filter.type === 'REGISTER') &&
          this.isFilterSet(filter.path)
        ) {
          query.search +=
            filter.path + ':' + this.getFilterValue(filter.path) + ',';
        } else if (
          filter.type === 'DATE-RANGE' ||
          filter.type === 'NUMBER-RANGE'
        ) {
          let sincePath = filter.path + '_more';
          let beforePath = filter.path + '_less';
          if (this.isFilterSet(sincePath))
            query.search +=
              filter.path + '>' + this.getFilterValue(sincePath) + ',';
          if (this.isFilterSet(beforePath))
            query.search +=
              filter.path + '<' + this.getFilterValue(beforePath) + ',';
        }
      }

      if (
        this.config.apiConfigPresets &&
        this.config.apiConfigPresets.length > 0
      ) {
        if (query.search.length > 0) query.search += ',';
        query.search += this.config.apiConfigPresets;
      }

      if (query.search.length === 0) delete query.search;
      else if (query.search.charAt(query.search.length - 1) === ',')
        query.search = query.search.substring(0, query.search.length - 1); //убрать последний символ - это ','
      console.log('query params: ', query);
      return query;
    },

    //задан ли фильтр с path
    isFilterSet(path) {
      return (
        this.getFilterValue(path) != null &&
        this.getFilterValue(path).toString().length > 0
      );
    },

    //получить значение фильтра с path
    getFilterValue(path) {
      return this.filters[path];
    },

    //убирает из фильтров пустые поля
    //ввели фильтр - стерли фильтр. Получился пустой фильтр. Его нужно убрать
    removeEmptyFilters() {
      for (let attr in this.filters) {
        if (
          this.filters[attr] == null ||
          this.filters[attr].length === 0 ||
          this.filters[attr] === false
        )
          delete this.filters[attr];
      }
    },

    //загрузить следующую страницу реестра
    async loadNextPage(year = this.year) {
      this.loadingInProgress = true;
      this.page++;
      let portion = (await api.get(this.config.apiRestEndpoint + year)).payload;
      let portion_minus = (
        await api.get(this.config.apiRestEndpoint + (year - 1))
      ).payload;
      let portion_plus = (
        await api.get(this.config.apiRestEndpoint + (year + 1))
      ).payload;
      console.log('3');
      if (this.config.customFilterFunction != null)
        portion = this.filterWithCustomFilterFunction(portion);
      this.entries = this.entries.concat(portion);
      this.entries = this.entries.concat(portion_minus);
      this.entries = this.entries.concat(portion_plus);
      this.entries = await wDec(this.entries);
      this.entries.map((item) => (item.data.sum = +item.data.sum));
      this.entries.forEach((item) => {
        // console.log('item', item);
        if (item.data.project) {
          if (!this.projectsDict.ids.includes(item.data.project.id)) {
            this.projectsDict.ids.push(item.data.project.id);
            item.data.project.code = item.data.project.id;
            item.data.project.title = item.data.project.name;
            this.projectsDict.projects.push(item.data.project);
            console.log(item.data.project.id, item.data.project.name);
          }
        }
      });
      this.filteredEntries = this.entries.filter((item) => item);
      console.log('WRegisterTab', this.entries);
      this.hasMorePages = portion.length === this.config.pageSize;
      this.loadingInProgress = false;
    },

    //фильтрует массив записей с помощью this.config.customFilterFunction
    //customFilterFunction возвращает либо null (тогда запись не включается), либо запись, которую нужно включить
    filterWithCustomFilterFunction(entries) {
      let res = [];
      for (let e of entries) {
        let r = this.config.customFilterFunction(e);
        if (r) {
          res.push(r);
        }
      }
      return res;
    },

    //возвращает текущее поле для сортировки, как объект. нужно для отображения в интерфейсе поля, по которому идет сортировка
    getSortField() {
      for (let field of this.config.sortFields) {
        if (field.path === this.sortField) {
          return field;
        }
      }
    },

    async exportRegisterData() {
      this.showDownloadProgressDialog = true;
      const waitPromise = new Promise((resolve) => setTimeout(resolve, 1000));
      await downloadFile(
        this.config.apiRestEndpoint + '/export/',
        this.formQuerySpec()
      );
      await waitPromise;
      this.showDownloadProgressDialog = false;
    },

    setSortParamsFromUrl(urlParams) {
      if (urlParams.sort) {
        let [sortField, sortDirection] = urlParams.sort.split(',');
        this.sortField = sortField;
        this.sortAsc = sortDirection === 'asc';
      } else {
        this.sortField = this.config.defaultSortField;
      }
    },

    setSearchParamsFromUrl(urlParams) {
      this.filters = {};
      let search = urlParams.search;
      if (!search) return;
      search += ',';
      const regex = new RegExp('([\\w\\.а-я]+)([:<>$])([\\wа-я| -]+),', 'g');
      for (let m of search.matchAll(regex)) {
        if (m[2] === ':') {
          this.filters[m[1]] = +m[3] ? +m[3] : m[3];
        } else if (m[2] === '>') {
          this.filters[m[1] + '_more'] = +m[3];
        } else if (m[2] === '<') {
          this.filters[m[1] + '_less'] = +m[3];
        }
      }
    },

    setParamsFromUrl(params) {
      const urlParams = params ?? getQueryObject();
      this.setSortParamsFromUrl(urlParams);
      this.setSearchParamsFromUrl(urlParams);
      console.log('urlParams', urlParams);
    },

    async beforeRouteUpdatedEvent(to) {
      this.setParamsFromUrl(to.query);
      await this.reloadRows();
    },
    setSearchDict(path, dict) {
      this.config.filters.find((e) => {
        return e.path === path;
      }).dict = dict;
    },
  },
  async beforeMount() {
    await this.loadData(
      '/supmain/experts?page=0&size=100&sort=id,asc&search=isRoleOffer:true',
      'offerExperts',
      true
    );
    await this.loadData('/supmain/contracts', 'contracts', true);
    this.contracts = this.contracts.map((item) => {
      item.code = item.id;
      item.title = item.contractNumber;
      return item;
    });
    // подгружаем словари фильтров
    this.setSearchDict('data.contractId.id', this.contracts);

    // собираем массив опций фондов из объекта, приводим в соответствие с словарем поиска
    let fundsSelectDict = Object.values(this.fundsDict)
      .reduce((total, current) => {
        return [...total, ...current];
      }, [])
      .map((item) => {
        item.code = item.id;
        item.title = item.fund;
        return item;
      });
    this.setSearchDict(
      'data.funds.id',
      fundsSelectDict
      // this.fundsDict.expences.map((item) => {
      //   item.code = item.id;
      //   item.title = item.fund;
      //   return item;
      // })
    );
    // собираем массив опций фондов из объекта, приводим в соответствие с словарем поиска
    let articlesSelectDict = Object.values(this.articlesDict)
      .reduce((total, current) => {
        return [...total, ...current];
      }, [])
      .map((item) => {
        item.code = item.id;
        item.title =
          item.article != '-'
            ? item.article
            : item.fundName + ' - ' + item.article;
        return item;
      });
    this.setSearchDict('data.article.id', articlesSelectDict);

    this.setSearchDict('data.project.id', this.projectsDict.projects);
    const getFullName = function (items) {
      return items.map((e) => {
        e.fullName = [e.surname, e.name, e.middleName].join(' ');
        if (e.fullName === '  ') e.fullName = 'Без имени';
        e.code = e.id;
        e.title = e.fullName;
        return e;
      });
    };
    this.offerExperts = getFullName(this.offerExperts);
    this.setSearchDict('data.expert.id', this.offerExperts);
    this.setParamsFromUrl();

    await this.loadNextPage();

    // ------------------------------
    const filterKeys = Object.keys(this.filters);
    filterKeys.forEach((filterItem) => {
      this.filteredEntries = this.filteredEntries.filter((item) => {
        if (
          filterItem.split('_').length > 0 &&
          filterItem.split('_')[1] == 'more'
        ) {
          return (
            filterItem
              .split('_')[0]
              .split('.')
              .reduce((o, k) => (o || {})[k], item) >= this.filters[filterItem]
          );
        } else if (
          filterItem.split('_').length > 0 &&
          filterItem.split('_')[1] == 'less'
        ) {
          return (
            filterItem
              .split('_')[0]
              .split('.')
              .reduce((o, k) => (o || {})[k], item) <= this.filters[filterItem]
          );
        } else {
          return (
            filterItem.split('.').reduce((o, k) => (o || {})[k], item) ==
            this.filters[filterItem]
          );
        }
      });
    });
    if (this.sortField == 'id') {
      if (this.sortAsc == true) {
        this.filteredEntries.sort((a, b) => a.id - b.id);
      } else {
        this.filteredEntries.sort((a, b) => b.id - a.id);
      }
    }
    //   -----------------------------
    this.firstPageLoaded = true;
    console.log('filter', this.config.filters);
    console.log('filters:', this.filters);
    console.log('sort field', this.sortField);
  },
};
</script>

<style>
.v-input--radio-group--row .v-input__slot {
  background: none !important;
}
</style>
